<template>
    <div class="schoolDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="schoolForm" ref="schoolDialogForm" :rules="schoolRules">

                <el-form-item label="单位名称"  prop="name" :label-width="formLabelWidth">
                    <el-input v-model="schoolForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="校区"  prop="area" :label-width="formLabelWidth">
                    <el-input v-model="schoolForm.area" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="地址"  prop="address" :label-width="formLabelWidth">
                    <el-input v-model="schoolForm.address" type="textarea"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'schoolDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                schoolForm:{ id: null, licenceId: null,  name: null, area: null, address: null,  },
                schoolRules: {
                    name: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            school: {
                type: Object
            }
        },
        watch: {
            school: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.schoolForm = { id: n.id, licenceId: n.licence_id, name: n.name, area: n.area, address: n.address};
                        if(n.id == null) this.myTitle = '新建单位'; else this.myTitle = '编辑单位';
                    }else{
                        this.schoolForm = { id: null, licenceId: null, name: null, area: null, address: null, };
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){
                console.log(this.schoolForm);
                let that = this;
                this.$refs.schoolDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.schoolForm.licenceId = this.userInfo.licenceId;
                        let param = { controllerName: 'school', methodName: '/saveOrUpdate', param: this.schoolForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('school', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该单位已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .schoolDialog{

    }


</style>


